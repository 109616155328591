import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Helmet from 'react-helmet';

const Track = () => {
  const [number, setNumber] = useState('');
  const [error, setError] = useState({});

  const submitCnic = e => {
    e.preventDefault();
    setError({ msg: 'Please Enter correct 6 digits number' });
  };

  return (
    <div id="wrapper">
      <Helmet>
        <title>Track your Licence</title>
      </Helmet>
      <section className="py-5">
        <Card>
          <Card.Body>
            {error ? <p className="text-center">{error.msg}</p> : null}
            <form>
              <input
                type="number"
                value={number}
                className="form-control"
                placeholder="Please Enter 6 digit number. (123456)"
                onChange={e => setNumber(e.target.value)}
              />
              <button
                onClick={submitCnic}
                className="btn btn-outline-success btn-lg mt-3"
              >
                Track Licence
              </button>
            </form>
          </Card.Body>
        </Card>
      </section>
    </div>
  );
};

export default Track;
